.bg-lfg-primary {
  background-color: #4b725b;
}

.bg-lfg-primary-lighten {
  background-color: #658c75;
}

.bg-lfg-secondary {
  background-color: #e6dfd4;
}

.text-lfg-secondary {
  color: #e6dfd4;
}

.bg-lfg-secondary-darken {
  background-color: #cdc6bb;
}
